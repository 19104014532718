import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useParams, Link } from 'react-router-dom';
import './ProductDetails.css'; // Ensure this CSS includes the updated styles

const ProductDetail = () => {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [relatedLoading, setRelatedLoading] = useState(false);
  const [cartItems, setCartItems] = useState([]);
  const [error, setError] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [modalImage, setModalImage] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);
  const [totalSlides, setTotalSlides] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const [firstName, setFirstName] = useState('');
  const [secondName, setSecondName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [location, setLocation] = useState('');

  const imagesSliderRef = useRef(null);

  // Fetch product details
  const fetchProduct = async () => {
    try {
      const response = await fetch(`http://localhost:8000/api/products/${id}/`);
      if (!response.ok) throw new Error('Failed to fetch product');
      const data = await response.json();
      setProduct(data);

      // Calculate total slides
      const additionalImages = data.additional_images || [];
      setTotalSlides(1 + additionalImages.length);
    } catch (error) {
      console.error('Error fetching product:', error);
      setError('Failed to load product details');
    }
  };

  useEffect(() => {
    fetchProduct();
  }, [id]);

  // Fetch related products
  useEffect(() => {
    if (product?.category?.id) {
      setRelatedLoading(true);

      const fetchRelatedProducts = async () => {
        try {
          const relatedResponse = await fetch(`http://localhost:8000/api/products/?category=${product.category.id}`);
          if (!relatedResponse.ok) throw new Error('Failed to fetch related products');
          const relatedData = await relatedResponse.json();

          const otherCategoriesResponse = await fetch(`http://localhost:8000/api/products/?exclude_category=${product.category.id}`);
          if (!otherCategoriesResponse.ok) throw new Error('Failed to fetch other category products');
          const otherCategoriesData = await otherCategoriesResponse.json();

          const combinedProducts = Array.from(new Set([...relatedData, ...otherCategoriesData].map(p => p.id)))
            .map(id => [...relatedData, ...otherCategoriesData].find(p => p.id === id));

          setRelatedProducts(combinedProducts.filter(p => p.id !== product.id));
        } catch (error) {
          console.error('Error fetching related products:', error);
          setError('Failed to load related products');
        } finally {
          setRelatedLoading(false);
        }
      };

      fetchRelatedProducts();
    }
  }, [product?.category?.id, product?.id]);

  // Handle image slider change
  const changeSlide = useCallback((direction) => {
    if (imagesSliderRef.current && totalSlides > 0) {
      const newIndex = (currentIndex + direction + totalSlides) % totalSlides;
      setCurrentIndex(newIndex);
      imagesSliderRef.current.style.transition = 'none'; // Disable transition for instant change
      imagesSliderRef.current.style.transform = `translateX(-${newIndex * 100}%)`;
      
      // Re-enable transition and handle reset
      setTimeout(() => {
        imagesSliderRef.current.style.transition = 'transform 0.5s ease';
      }, 50); // Ensure the transition is re-enabled after the change
    }
  }, [currentIndex, totalSlides]);

  // Automatic sliding for images
  useEffect(() => {
    if (imagesSliderRef.current && totalSlides > 0) {
      // Clone the first and last slides for smooth transition
      const firstSlide = imagesSliderRef.current.children[0];
      const lastSlide = imagesSliderRef.current.children[totalSlides - 1];
      
      // Append clones
      imagesSliderRef.current.appendChild(firstSlide.cloneNode(true));
      imagesSliderRef.current.insertBefore(lastSlide.cloneNode(true), imagesSliderRef.current.firstChild);
      
      // Set initial position
      setCurrentIndex(1); // Start from the second slide (0-indexed)
      imagesSliderRef.current.style.transform = `translateX(-${100}%)`;
    }
  }, [totalSlides]);

  // Handle adding item to cart
  const handleAddToCart = async () => {
    try {
      const productId = parseInt(id, 10);
      const data = {
        product: productId,
        quantity: quantity,
        user_info: {
          first_name: firstName,
          second_name: secondName,
          email,
          phone_number: phoneNumber,
          location,
        },
      };

      const response = await fetch('http://localhost:8000/api/orders/cart/add/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error('Failed to add item to cart');
      }

      // Fetch updated product information
      await fetchProduct();
      alert('Item added to cart!');
      fetchCartItems();
    } catch (error) {
      console.error('Error adding item to cart:', error);
      setError('Failed to add item to cart');
    }
  };

  // Fetch cart items
  const fetchCartItems = async () => {
    try {
      const response = await fetch('http://localhost:8000/api/orders/list/', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) throw new Error('Failed to fetch cart items');
      const data = await response.json();
      setCartItems(data.items);
    } catch (error) {
      console.error('Error fetching cart items:', error);
      setError('Failed to load cart items');
    }
  };

  // Convert comma-separated descriptions to list items
  const descriptionList = product?.description
    ? product.description.split(',').map((desc, index) => <li key={index}>{desc.trim()}</li>)
    : null;

  // Handle image click to show modal
  const handleImageClick = (imageSrc) => {
    setModalImage(imageSrc);
    setShowModal(true);
  };

  // Close modal
  const closeModal = () => {
    setShowModal(false);
    setModalImage('');
  };

  // Update quantity
  const increaseQuantity = () => {
    setQuantity(prev => {
      if (product && prev < product.stock) {
        return prev + 1;
      }
      return prev;
    });
  };

  const decreaseQuantity = () => {
    setQuantity(prev => (prev > 1 ? prev - 1 : 1)); // Ensure quantity doesn't go below 1
  };

  if (error) return <div className="error">{error}</div>;
  if (!product) return <div className="loading">Loading...</div>;

  return (
    <div className="product-detail">
      <div className="image-info-container">
        <div className="images-slider-container">
          <div className="images-slider" ref={imagesSliderRef}>
            {product.image && (
              <div className="image-slide" onClick={() => handleImageClick(`http://localhost:8000${product.image}`)}>
                <img src={`http://localhost:8000${product.image}`} alt={product.name} />
              </div>
            )}
            {(product.additional_images || []).map((image) => (
              <div
                key={image.id}
                className="image-slide"
                onClick={() => handleImageClick(
                  image.additional_image && image.additional_image.startsWith('/')
                    ? `http://localhost:8000${image.additional_image}`
                    : image.additional_image || ''
                )}
              >
                <img
                  src={image.additional_image && image.additional_image.startsWith('/')
                    ? `http://localhost:8000${image.additional_image}`
                    : image.additional_image || ''}
                  alt={`Additional view of ${product.name}`}
                />
              </div>
            ))}
          </div>
          <button className="slider-arrow left" onClick={() => changeSlide(-1)}>‹</button>
          <button className="slider-arrow right" onClick={() => changeSlide(1)}>›</button>
        </div>
        <div className="product-info">
          <h1 className="product-name">{product.name}</h1>
          <div className="product-description">
            <ul>{descriptionList}</ul>
          </div>
          <div className="product-details">
            <div className="price-container">
              <div className="product-price">Kshs.<span> {product.price}</span></div>
              <div className="product-stock">Stock: {product.stock}</div>
            </div>
            <div className="quantity-container">
              <button className="quantity-button" onClick={decreaseQuantity}>-</button>
              <span className="quantity">{quantity}</span>
              <button className="quantity-button" onClick={increaseQuantity}>+</button>
            </div>
            <button className="add-to-cart-button" onClick={handleAddToCart}>Add to Cart</button>
          </div>
        </div>
      </div>
      <div className="user-info">
        <h2>User Information</h2>
        <input
          type="text"
          placeholder="First Name"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
        <input
          type="text"
          placeholder="Second Name"
          value={secondName}
          onChange={(e) => setSecondName(e.target.value)}
        />
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="tel"
          placeholder="Phone Number"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
        />
        <input
          type="text"
          placeholder="Location"
          value={location}
          onChange={(e) => setLocation(e.target.value)}
        />
      </div>
      {showModal && (
        <div className="modal">
          <span className="close" onClick={closeModal}>&times;</span>
          <img className="modal-content" src={modalImage} alt="Modal" />
        </div>
      )}
      <div className="related-products">
        <h2>Related Products</h2>
        {relatedLoading ? (
          <div className="loading">Loading related products...</div>
        ) : (
          <div className="related-products-list">
            {relatedProducts.map((prod) => (
              <div key={prod.id} className="related-product">
                <Link to={`/products/${prod.id}`}>
                  <img src={`http://localhost:8000${prod.image}`} alt={prod.name} />
                  <div className="related-product-info">
                    <h3>{prod.name}</h3>
                    <p>Kshs. {prod.price}</p>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductDetail;
