// src/contexts/UserContext.js
import React, { createContext, useState, useContext, useEffect } from 'react';

// Create the UserContext
const UserContext = createContext();

// Create the Provider for UserContext
const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  // Mock authentication (you can replace this with real API calls)
  useEffect(() => {
    const fetchUser = async () => {
      // Replace this with a real API call or authentication logic
      const storedUser = localStorage.getItem('user');
      if (storedUser) {
        setUser(JSON.parse(storedUser));
      }
    };

    fetchUser();
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};

// Custom hook to use the UserContext
const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};

export { UserProvider, useUser };
