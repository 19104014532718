import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useUser } from '../contexts/UserContext'; // Ensure this path is correct
import './Navbar.css';
import logoImage from '../assets/logo.png'; // Adjust the path to your logo image

const Navbar = () => {
  const { user } = useUser(); // Get user from context

  useEffect(() => {
    let lastScrollTop = 0;

    const handleScroll = () => {
      const menu = document.querySelector('.navbar-menu');
      const currentScroll = window.pageYOffset || document.documentElement.scrollTop;

      if (currentScroll > lastScrollTop) {
        menu.classList.add('hidden');
      } else {
        menu.classList.remove('hidden');
      }
      lastScrollTop = currentScroll <= 0 ? 0 : currentScroll;
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div>
      <nav className="navbar">
        <div className="navbar-top">
          <Link to="/" className="navbar-logo">
            <img src={logoImage} alt="Logo" className="logo-image" />
          </Link>

          <div className="navbar-icons">
            <div className="icon-item">
              <Link to="orders/list/" className="icon">
                🛒
                <span className="icon-label">Cart</span>
              </Link>
            </div>
            <div className="icon-item">
              <Link to="/wishlist" className="icon">
                ❤️
                <span className="icon-label">Wishlist</span>
              </Link>
            </div>
            {user ? (
              <div className="icon-item">
                <Link to="/profile" className="icon">
                  👤
                  <span className="icon-label">Hello, {user.first_name}</span>
                </Link>
              </div>
            ) : (
              <div className="icon-item">
                <Link to="/login" className="icon">
                  👤
                  <span className="icon-label">My Account</span>
                </Link>
              </div>
            )}
          </div>
        </div>
      </nav>
      <ul className="navbar-menu">
        <li><Link to="/">Home</Link></li>
        <li><Link to="/category/Laptops">Laptops</Link></li>
        <li><Link to="/category/Computer Desktops">Computer Desktops</Link></li>
        <li className="dropdown">
          <Link to="#" className="dropdown-toggle">Microsoft Products</Link>
          <ul className="dropdown-menu">
            <li><Link to="/category/Microsoft Windows">Microsoft Windows</Link></li>
            <li><Link to="/category/Microsoft Office">Microsoft Office</Link></li>
          </ul>
        </li>
        <li><Link to="/category/Computer Accessories">Computer Accessories</Link></li>
        <li><Link to="/category/Antivirus">Antivirus</Link></li>
        <li className="dropdown">
          <Link to="#" className="dropdown-toggle">Other Services</Link>
          <ul className="dropdown-menu">
            <li><Link to="/category/Spares">Spares</Link></li>
            <li><Link to="/category/Repair Services">Repair Services</Link></li>
          </ul>
        </li>
      </ul>
      <div className="sidebar">
        <Link to="/" className="sidebar-item">Home</Link>
        <Link to="/category/Laptops" className="sidebar-item">Laptops</Link>
        <Link to="/category/Computer Desktops">Computer Desktops</Link>
        <Link to="/category/Microsoft Windows" className="sidebar-item">Microsoft Windows</Link>
        <Link to="/category/Microsoft Office" className="sidebar-item">Microsoft Office</Link>
        <Link to="/category/Computer Accessories">Computer Accessories</Link>
        <Link to="/category/Antivirus">Antivirus</Link>
        <Link to="/category/Spares">Spares</Link>
        <Link to="/category/Repair Services">Repair Services</Link>
      </div>
    </div>
  );
};

export default Navbar;
