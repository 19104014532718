import React from 'react';
import './Footer.css';  // Import CSS for styling

const Footer = () => (
  <footer className="footer">
    <div className="footer-container">
      <p>&copy; {new Date().getFullYear()} E-Commerce. All rights reserved.</p>
      <ul className="footer-menu">
        <li><a href="/about">About Us</a></li>
        <li><a href="/contact">Contact</a></li>
        <li><a href="/privacy">Privacy Policy</a></li>
        <li><a href="/terms">Terms of Service</a></li>
      </ul>
    </div>
  </footer>
);

export default Footer;
