import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './Cart.css';

function Cart() {
  const { id } = useParams(); // Get the ID from the URL if needed
  const [cart, setCart] = useState(null); // Store the entire cart object
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function loadCart() {
      try {
        // Extract cart_id from cookies
        const cartIdCookie = document.cookie.split('; ').find(row => row.startsWith('cart_id='));
        const cartId = cartIdCookie ? cartIdCookie.split('=')[1] : null;

        const token = localStorage.getItem('authToken');
        const baseUrl = 'http://localhost:8000';
        const url = cartId 
          ? `${baseUrl}/api/orders/cart/${cartId}/` 
          : `${baseUrl}/api/orders/cart/`;

        const headers = token 
          ? { 'Authorization': `Bearer ${token}` }
          : {}; // No authorization header for unauthenticated users

        const response = await fetch(url, { headers });
        if (!response.ok) {
          throw new Error('Failed to fetch cart data');
        }

        const data = await response.json();
        console.log('Fetched cart data:', data); // Check the data here

        setCart(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    }
  
    loadCart();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  // Check if cart exists and has items
  if (!cart || !cart.items || cart.items.length === 0) {
    return <p>Your cart is empty.</p>;
  }

  return (
    <div className="cart-page">
      <h1>Your Cart</h1>
      <ul>
        {cart.items.map(item => (
          <li key={item.id}>
            {item.product.name} - {item.quantity} x ${item.product.price.toFixed(2)}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Cart;
